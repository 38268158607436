@import "https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;900&display=swap";
@keyframes title {
  0% {
    flex-wrap: wrap;
    line-height: .9pt;
  }

  80% {
    font-size: .78vw;
    line-height: .3pt;
  }

  95% {
    flex-direction: column-reverse;
  }

  100% {
    line-height: .9pt;
  }
}

@keyframes button {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.screen {
  flex-direction: column;
  height: 100vh;
  display: flex;
}

#title-box {
  justify-content: center;
  font-weight: bold;
}

#title-box .title {
  text-align: center;
  flex-direction: column;
  margin-bottom: 5vh;
  font-size: .9vw;
  animation: 1s step-end 2 title;
  display: flex;
  overflow-y: hidden;
}

#title-box .title div {
  color: #fff;
  display: inline-block;
  overflow-y: hidden;
}

@media (width <= 812px) {
  #title-box .title div {
    letter-spacing: .01px;
    font-size: 1.3vw;
  }
}

@media (height <= 480px) {
  #title-box .title div {
    font-size: .99vw;
  }
}

#title-box .title div > div {
  padding: 1vh 0;
}

#title-box .title div > span {
  color: #fff;
}

@media (width <= 812px) {
  #title-box .title {
    width: 90vw;
    height: auto;
    margin: 0;
    padding: 3vw;
    font-weight: 900;
  }
}

#title-box .button {
  animation: 2.3s step-end button;
}

#listen .audios {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

@media (width <= 430px) {
  #listen .audios .listen-arrows {
    display: none;
  }
}

#listen .audios > div {
  flex-direction: row;
  width: fit-content;
  padding-bottom: 4vh;
  display: flex;
}

@media (width <= 430px) {
  #listen .audios > div {
    flex-direction: column;
  }
}

#listen .audios > div > span {
  -webkit-user-select: none;
  user-select: none;
}

@media (width >= 430px) {
  #listen .audios > div > span {
    align-self: center;
    display: flex;
  }
}

@media (width <= 430px) {
  #listen .audios > div > span {
    position: relative;
    left: 0;
  }
}

#listen .arrow {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 100vh;
  margin-top: 0;
  font-size: 12pt;
  display: flex;
  position: absolute;
  top: 100vh;
  overflow: hidden;
}

#listen .arrow.left {
  left: 3vw;
}

#listen .arrow.right {
  right: 3vw;
}

#listen .arrow a {
  text-align: center;
  color: #fff;
  white-space: nowrap;
  height: auto;
  margin-bottom: 1vh;
  display: block;
  overflow: hidden;
  transform: rotate(-90deg);
}

#listen .arrow a.flip {
  transform: rotate(90deg);
}

#intro {
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

#intro .content-wrapper {
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  display: flex;
}

#intro .content-wrapper .Typewriter {
  width: 70vw;
  height: 85%;
}

@media (width <= 430px) {
  #intro .content-wrapper .Typewriter {
    width: 80vw;
  }
}

#intro .content-wrapper > p {
  z-index: 100;
  font-size: 10pt;
  position: absolute;
  bottom: -200vh;
  right: 5vw;
}

@media (height <= 480px) {
  #intro .content-wrapper > p {
    text-align: center;
    width: 100vw;
    position: absolute;
    bottom: -196vh;
    left: 0;
  }
}

@media (width <= 430px) {
  #intro .content-wrapper > p {
    width: 0;
  }
}

#intro .content-wrapper .home-nav {
  text-align: center;
  flex-flow: column;
  justify-content: flex-end;
  align-self: center;
  width: 45vw;
  height: fit-content;
  margin-bottom: 5vh;
  display: flex;
  position: absolute;
  bottom: -203vh;
}

#intro .content-wrapper .home-nav p {
  margin: 0;
}

@media (height <= 480px) {
  #intro .content-wrapper .home-nav {
    flex-flow: wrap;
    justify-content: space-around;
    width: 60vw;
  }
}

@media (width <= 430px) {
  #intro .content-wrapper .home-nav {
    font-size: 10pt;
  }
}

#essay {
  flex-direction: column;
  align-items: center;
  padding-top: 2vh;
  padding-bottom: 5vh;
  display: flex;
}

#essay nav {
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 5vh;
  left: 2vw;
}

#essay .title {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin-top: 3vh;
  font-size: .8vw;
  font-weight: 900;
  display: flex;
}

#essay .title > div {
  flex-wrap: wrap;
  padding: 1vh 0;
  display: flex;
}

#essay .title > div div {
  padding: 0 1vw;
}

#essay .title div {
  color: #fff;
  background-color: #fff;
  padding: .5vh;
}

#essay .title span {
  color: #000;
}

#essay .text {
  flex-direction: column;
  margin-top: 2vh;
  line-height: 1.5;
  display: flex;
}

#essay .text .footnotemark, #essay .text .footnotetext > sup, #essay .text .quotemark {
  cursor: pointer;
  text-decoration: underline;
}

#essay .text h3 {
  margin-bottom: .5vh;
  padding: 0;
}

@media (width >= 812px) {
  #essay .text {
    width: 70vw;
  }
}

@media (width <= 812px) {
  #essay .text {
    width: 80vw;
    font-size: .9em;
  }
}

#essay #refs, #essay #footnotes {
  flex-direction: column;
  width: 80vw;
  display: flex;
}

#essay #refs a, #essay #footnotes a {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

#essay #refs h3, #essay #footnotes h3 {
  margin-top: 0;
}

#essay #refs span, #essay #footnotes span {
  padding: 6px 0 3px;
}

#essay #refs sup, #essay #footnotes sup {
  padding-right: .5vw;
}

#essay #refs .separator, #essay #footnotes .separator {
  text-align: center;
  width: 100%;
}

#essay #footnotes {
  margin-bottom: 2vh;
}

#essay .phonos {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin-top: 3vh;
  display: flex;
}

#essay .phonos p {
  flex-direction: row;
  display: flex;
}

#videos > nav {
  position: fixed;
  bottom: 5vh;
  left: 2vw;
}

#videos #video-1, #videos #video-2 {
  width: 100vw;
  height: 100vh;
}

#videos #video-1 a, #videos #video-2 a {
  position: absolute;
}

#videos #video-1 a {
  bottom: 1vh;
}

#videos #video-2 a {
  bottom: -99vh;
}

@media (height <= 480px) {
  #videos #video-2 a {
    bottom: -95.5vh;
  }
}

#videos .video {
  justify-content: center;
  align-items: center;
  height: 95vh;
  display: flex;
}

#videos .video > div {
  width: 40%;
  top: 3vh;
}

#videos .video > span {
  width: 40%;
  height: 30vh;
  padding-right: 15vw;
}

@media (width <= 812px) {
  #videos .video {
    flex-direction: column;
  }

  #videos .video > div {
    width: 70vw;
  }

  #videos .video > span {
    width: 80vw;
    margin-top: 2vh;
    padding-right: 0;
  }
}

#videos #video-2 .video {
  flex-direction: column;
  padding-top: 4vh;
}

#videos #video-2 .video img {
  width: 70vw;
}

@media (height <= 480px) {
  #videos #video-2 .video img {
    width: 50vw;
  }
}

#videos #video-2 .video > span {
  margin-top: 2vh;
  padding-right: 0;
}

#videos .phonos {
  text-align: center;
  width: 100vw;
  line-height: 2;
  position: absolute;
  bottom: -100vh;
}

* {
  color: #fff;
  background: #000;
  font-family: Inconsolata, monospace;
  font-weight: 300;
  overflow-x: hidden;
}

* button {
  background: none;
  border: none;
}

* ::-webkit-scrollbar {
  width: 8px;
}

* ::-webkit-scrollbar-thumb {
  background-color: #000;
  border: 1px solid #fff;
}

* .selector {
  background-color: #000;
  border: 1px solid #fff;
  scrollbar-color: black !important;
}

main .title {
  -webkit-user-select: none;
  user-select: none;
}

nav {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.button {
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  justify-content: center;
  width: 100vw;
  height: 15%;
  font-size: 12pt;
  display: flex;
  overflow: hidden;
}

.button div {
  align-items: center;
  width: fit-content;
  display: flex;
  bottom: 10vh;
  transform: rotate(90deg);
}

.button.flip {
  transform: scale(1, -1);
}

#tldr, #aboutme, #tech {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#tldr nav, #aboutme nav, #tech nav {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 2vh;
}

#tldr nav p, #tldr nav a, #aboutme nav p, #aboutme nav a, #tech nav p, #tech nav a {
  margin-bottom: 1vh;
  display: flex;
}

#tldr > p, #tldr > h3, #tldr > .audio, #aboutme > p, #aboutme > h3, #aboutme > .audio, #tech > p, #tech > h3, #tech > .audio {
  width: 70vw;
}

.M79QDa_AudioPlayer {
  height: fit-content;
  display: flex;
  position: relative;
}

.M79QDa_AudioPlayer button {
  cursor: pointer;
  width: 32px;
  height: 50px;
  overflow: hidden;
}

.M79QDa_AudioPlayer button img {
  margin-top: 1.72px;
}

.M79QDa_AudioPlayer p {
  -webkit-user-select: none;
  user-select: none;
  word-wrap: initial;
  align-items: center;
  width: auto;
  display: flex;
}

.M79QDa_AudioPlayer > div {
  border: 1px solid #fff;
  align-self: center;
  width: 20vw;
  height: 7px;
  display: grid;
  position: relative;
  right: 0;
  overflow: hidden;
}

@media (width <= 430px) {
  .M79QDa_AudioPlayer > div {
    width: 30vw;
  }
}

.M79QDa_AudioPlayer > div div {
  cursor: pointer;
  height: inherit;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 7px;
  position: absolute;
}

.M79QDa_AudioPlayer > div input {
  cursor: pointer;
  appearance: none;
  height: inherit;
  background: none;
  grid-area: 1 / 1;
  width: 100%;
  margin: 0;
}

.M79QDa_AudioPlayer > div input::-webkit-slider-thumb {
  appearance: none;
  opacity: 0;
  background: none;
  border: 0;
  width: 7px;
  height: 7px;
}

.M79QDa_AudioPlayer > div input::-moz-range-thumb {
  appearance: none;
  opacity: 0;
  background: none;
  border: 0;
  width: 7px;
  height: 7px;
}
/*# sourceMappingURL=index.4b1cbd06.css.map */
