// animation
$title-anim-time: 1s;
@keyframes title {
	0% {
		line-height: 0.9pt;
		flex-wrap: wrap;
	}
	10% {
	}
	80% {
		font-size: 0.78vw;
		line-height: 0.3pt;
	}
	95% {
		flex-direction: column-reverse;
	}
	100% {
		line-height: 0.9pt;
	}
}
@keyframes button {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// screens
.screen {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

// title
#title-box {
	justify-content: center;
	font-weight: bold;

	.title {
		overflow-y: hidden;
		text-align: center;
		margin-bottom: 5vh;
		display: flex;
		flex-direction: column;
		animation: title $title-anim-time step-end 2 normal;
		font-size: $title-font-size;

		div {
			overflow-y: hidden;
			// background
			color: white;
			display: inline-block;
			@media (max-width: $ipad) {
				letter-spacing: 0.01px;
				font-size: 1.3vw;
			}
			@media (max-height: $rot) {
				font-size: 1.1 * $title-font-size;
			}
			> div {
				padding: 1vh 0;
			}
			> span {
				// letters
				color: white;
			}
		}

		// mobile
		@media (max-width: $ipad) {
			margin: 0;
			font-weight: 900;
			padding: 3vw;
			height: auto;
			width: 90vw;
		}
	}
	.button {
		animation: button calc(2 * $title-anim-time + 0.3s) step-end normal;
	}
}

#listen {
	.audios {
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.listen-arrows {
			@media (max-width: $phone) {
				display: none;
			}
		}
		> div {
			width: fit-content;
			padding-bottom: 4vh;
			display: flex;
			flex-direction: row;
			@media (max-width: $phone) {
				flex-direction: column;
			}

			> span {
				user-select: none;
				@media (min-width: $phone) {
					display: flex;
					align-self: center;
				}
				@media (max-width: $phone) {
					position: relative;
					left: 0;
				}
			}
		}
	}

	.arrow {
		user-select: none;
		overflow: hidden;
		position: absolute;
		top: 100vh;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 5vw;
		margin-top: 0;
		font-size: 12pt;
		cursor: pointer;

		&.left {
			left: 3vw;
		}
		&.right {
			right: 3vw;
		}
		a {
			transform: rotate(-90deg);
			height: auto;
			display: block;
			text-align: center;
			color: white;
			overflow: hidden;
			white-space: nowrap;
			margin-bottom: 1vh;
			&.flip {
				transform: rotate(90deg);
			}
		}
	}
}

//intro
#intro {
	margin-bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	.content-wrapper {
		height: 90vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.Typewriter {
			height: 85%;
			width: 70vw;
			@media (max-width: $phone) {
				width: 80vw;
			}
		}
		> p {
			position: absolute;
			bottom: -2 * 100vh;
			right: 5vw;
			font-size: 10pt;
			z-index: 100;
			@media (max-height: $rot) {
				left: 0;
				width: 100vw;
				position: absolute;
				bottom: -2 * 100vh + 4vh;
				text-align: center;
			}
			@media (max-width: $phone) {
				width: 0px;
			}
		}

		.home-nav {
			width: 45vw;
			p {
				margin: 0;
			}
			//width: fit-content;
			position: absolute;
			bottom: -2 * 100vh-3vh;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-end;
			text-align: center;
			align-self: center;
			margin-bottom: 5vh;
			height: fit-content;

			@media (max-height: $rot) {
				flex-direction: row;
				width: 60vw;
				justify-content: space-around;
				flex-wrap: wrap;
			}
			@media (max-width: $phone) {
				font-size: 10pt;
			}
		}
	}
}
