#videos {
	> nav {
		position: fixed;
		bottom: 5vh;
		left: 2vw;
	}
	#video-1,
	#video-2 {
		width: 100vw;
		height: 100vh;

		a {
			position: absolute;
		}
	}
	#video-1 {
		a {
			bottom: 1vh;
		}
	}
	#video-2 {
		a {
			bottom: -100vh + 1vh;
			@media (max-height: $rot) {
				bottom: -100vh + 4.5vh;
			}
		}
	}

	.video {
		height: 95vh;
		display: flex;
		justify-content: center;
		align-items: center;
		> div {
			top: 3vh;
			width: 40%;
		}
		> span {
			width: 40%;
			padding-right: 15vw;
			height: 30vh;
		}

		@media (max-width: $ipad) {
			flex-direction: column;
			> div {
				width: 70vw;
			}
			> span {
				width: 80vw;
				padding-right: 0;
				margin-top: 2vh;
			}
		}
	}
	#video-2 {
		.video {
			flex-direction: column;
			padding-top: 4vh;

			img {
				width: 70vw;
				@media (max-height: $rot) {
					width: 50vw;
				}
			}
			> span {
				padding-right: 0;
				margin-top: 2vh;
			}
		}
	}
	.phonos {
		width: 100vw;
		text-align: center;
		position: absolute;
		line-height: 2;
		bottom: -1 * 100vh;
	}
}
