#essay {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 5vh;
	padding-top: 2vh;
	nav {
		position: fixed;
		display: flex;
		flex-direction: column;
		bottom: 5vh;
		left: 2vw;
	}
	.title {
		width: 80vw;
		font-weight: 900;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 3vh;
		font-size: 0.8vw;
		> div {
			display: flex;
			flex-wrap: wrap;
			padding: 1vh 0;
			div {
				padding: 0 1vw;
			}
		}

		background-color: white;

		div {
			padding: 0.5vh;
			background-color: white;

			color: white;
		}
		span {
			color: black;
		}
	}
	.text {
		line-height: 1.5;
		margin-top: 2vh;
		display: flex;
		flex-direction: column;
		.footnotemark,
		.footnotetext > sup,
		.quotemark {
			cursor: pointer;
			text-decoration: underline;
		}
		h3 {
			padding: 0;
			margin-bottom: 0.5vh;
		}

		@media (min-width: $ipad) {
			width: 70vw;
		}
		@media (max-width: $ipad) {
			font-size: 0.9em;
			width: 80vw;
		}
	}

	#refs,
	#footnotes {
		width: 80vw;
		display: flex;
		flex-direction: column;
		a {
			overflow-wrap: break-word;
			word-wrap: break-word;
		}
		h3 {
			margin-top: 0;
		}
		span {
			padding: 6px 0 3px 0;
		}
		sup {
			padding-right: 0.5vw;
		}
		.separator {
			width: 100%;
			text-align: center;
		}
	}
	#footnotes {
		margin-bottom: 2vh;
	}
	.phonos {
		width: 80vw;
		margin-top: 3vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		p {
			display: flex;
			flex-direction: row;
		}
	}
}
