// font
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;900&display=swap');

// vars
$ipad: 812px;
$phone: 430px;
$rot: 480px;

$green: #4af626;
$title-font-size: 0.9vw;

// pages stylesheets
@import './home.scss';
@import './essay.scss';
@import './videos.scss';

* {
	background: black;
	color: white;
	font-family: 'Inconsolata', monospace;
	font-weight: 300;

	// remove default button styling
	button {
		border: none;
		background: none;
	}

	// scrollbar
	overflow-x: hidden;
	/* Chrome, safari */
	::-webkit-scrollbar {
		width: 8px;
	}
	::-webkit-scrollbar-thumb {
		background-color: black;
		border: 1px solid white;
	}

	/* Firefox */
	.selector {
		scrollbar-color: black !important;
		background-color: black;
		border: 1px solid white;
	}
}

main {
	.title {
		user-select: none;
	}
}
nav {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.button {
	cursor: pointer;
	width: 100vw;
	display: flex;
	justify-content: center;
	text-align: center;
	user-select: none;
	height: 15%;
	color: white;
	font-size: 12pt;
	overflow: hidden;

	div {
		display: flex;
		align-items: center;
		width: fit-content;
		bottom: 10vh;
		transform: rotate(90deg);
	}

	&.flip {
		transform: scale(1, -1);
	}
}

#tldr,
#aboutme,
#tech {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	nav {
		position: absolute;
		bottom: 2vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		p,
		a {
			display: flex;
			margin-bottom: 1vh;
		}
	}
	> p,
	> h3,
	> .audio {
		width: 70vw;
	}
	/* 	@media (min-width: $ipad) {
		> h3,
		> .audio {
			width: 30vw;
		}
	} */
}
